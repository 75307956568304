import * as React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
//import News from "../components/News"
//import Newsletter from "../components/Newsletter"

const NewsPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div className="container mx-auto text-center">
      <h1 className="text-3xl uppercase font-bold my-10">Tech Typist Tidbits</h1>
      <p className="bg-gray-200 p-5 rounded">Coming soon...</p>
      <div className="mx-auto w-1/2 my-10">
        <Img fluid={data.file.childImageSharp.fluid} />
      </div>
    </div>
  </Layout>
)

export default NewsPage

export const query = graphql`
  query {
    file(relativePath: { eq: "images/coming-soon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
